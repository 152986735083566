.cover {
	position: relative;
	background: #ccc;
	background-image: url('https://unsplash.imgix.net/photo-1414490929659-9a12b7e31907?fit=crop&fm=jpg&h=800&q=75&w=1050');
	background-size: cover;
	background-position: center;
	min-height: 300px;
}
.cover button {
	position: absolute;
	top: 10px;
	right: 10px;
	display: none;
}
.cover h1 {
	position: absolute;
	left: 250px;
	bottom: 5%;
	font-weight: bold;
	color: #fff;
	text-shadow: 2px 2px 3px #333;
}
.cover:hover button {
	display: block;
}

.profileImg {
	position: relative;
}
.profileImg img {
	height: 150px;
	width: 150px;
	position: absolute;
	top: -100px;
	z-index: 999;
	border-radius: 50%;
}
.profileImg button {
	display: none;
	position: absolute;
	z-index: 1000;
	left: 15px;
	bottom: -50px;
}
.profileImg:hover button {
	display: block;
}

.profile {
	padding-top: 80px;
}

.content {
	min-height: 200px;
}
.content .profile-links {
	padding: 0;
	list-style-type: none;
	font-size: 1rem;
}

.feedItem {
	padding: 20px 0;
	border-bottom: 1px solid #ccc;
}
