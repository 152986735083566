.funkyradio div {
    clear: both;
    /*margin: 0 50px;*/
    overflow: hidden;
}

.funkyradio label {
    /*min-width: 400px;*/
    width: 100%;
    border-radius: 3px;
    border: 1px solid #D1D3D4;
    font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
    display: none;
}

.funkyradio input[type="radio"]:empty~label,
.funkyradio input[type="checkbox"]:empty~label {
    position: relative;
    line-height: 2.5em;
    /* text-indent: 3.25em; */
    margin-top: 2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.funkyradio input[type="radio"]:empty~label:before,
.funkyradio input[type="checkbox"]:empty~label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 40px;
    height:40px;
    /* background: #D1D3D4; */
    border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked)~label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked)~label:before {
    content: '\2713';
    text-indent: .9em;
    color: #5cb85c;
}

.funkyradio input[type="radio"]:hover:not(:checked)~label,
.funkyradio input[type="checkbox"]:hover:not(:checked)~label {
    color: #888;
}

.funkyradio input[type="radio"]:checked~label:before,
.funkyradio input[type="checkbox"]:checked~label:before {
    content: '\2713';
    text-indent: .9em;
    color: #333;
    background-color: #ccc;
}

.funkyradio input[type="radio"]:checked~label,
.funkyradio input[type="checkbox"]:checked~label {
    color: #777;
}

.funkyradio input[type="radio"]:focus~label:before,
.funkyradio input[type="checkbox"]:focus~label:before {
    box-shadow: 0 0 0 3px #999;
}



.funkyradio-success input[type="radio"]:checked~label:before,
.funkyradio-success input[type="checkbox"]:checked~label:before {
    color: #fff;
    background-color: #5cb85c;
}

